<template>
  <div class="flex flex-col gap-4 mt-14">
    <h1 class="text-2xl font-bold">Orders History (Only received orders)</h1>
    <CChart
        :redraw="true"
        type="line"
        :data="chartData"
      />
  </div>
</template>

<script setup>
import { CChart } from '@coreui/vue-chartjs'
import { ref, defineProps, computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { getOrdersHistory } from '../api/order'

const labels = ref([])
const acceptedData = ref([])
const rejectedData = ref([])
const completedData = ref([])
const expiredData = ref([])

const props = defineProps({
  startDate: String,
  endDate: String,
  orderType: String,
})

function formatDate(date) {
  const [fullDate, time] = date.split('T');
  const [year, month, day] = fullDate.split('-');
  const [hour, minute] = time.split(':');
  return `${day}/${month} ${hour}:${minute}`;
}

// Move fetchData into queryFn
const { data: queryData } = useQuery({
  queryKey: ['ordersHistory', props.startDate, props.endDate, props.orderType],
  queryFn: async () => {
    const data = await getOrdersHistory(props.startDate, props.endDate, props.orderType)
    
    const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]))

    labels.value = []
    acceptedData.value = []
    rejectedData.value = []
    completedData.value = []
    expiredData.value = []

    for (const [timestamp, orderData] of sortedData) {
      labels.value.push(formatDate(timestamp))
      acceptedData.value.push(orderData["aceptada"] || 0)
      rejectedData.value.push(orderData["rechazada"] || 0) 
      completedData.value.push(orderData["cumplida"] || 0)
      expiredData.value.push(orderData["vencida"] || 0)
    }

    return data
  },
  refetchInterval: 10000
})

const prettyLabels = computed(() => {
  const groupedLabels = []
  for (let i = 0; i < labels.value.length; i += 3) {
    groupedLabels.push(labels.value[i])
    groupedLabels.push('')
    groupedLabels.push('')
  }
  return groupedLabels
})

const chartData = computed(() => ({
  labels: prettyLabels.value,
  datasets: [
    {
      label: 'Accepted',
      backgroundColor: 'rgba(102, 3, 252, 0.2)',
      borderColor: 'rgba(102, 3, 252, 1)',
      pointBackgroundColor: 'rgba(102, 3, 252, 1)',
      pointBorderColor: '#fff',
      data: acceptedData.value
    },
    {
      label: 'Rejected',
      backgroundColor: 'rgba(252, 3, 227, 0.2)',
      borderColor: 'rgba(252, 3, 227, 1)',
      pointBackgroundColor: 'rgba(252, 3, 227, 1)',
      pointBorderColor: '#fff',
      data: rejectedData.value
    },
    {
      label: 'Completed',
      backgroundColor: 'rgba(27, 24, 33, 0.2)',
      borderColor: 'rgba(27, 24, 33, 1)',
      pointBackgroundColor: 'rgba(27, 24, 33, 1)',
      pointBorderColor: '#fff',
      data: completedData.value
    },
    {
      label: 'Expired',
      backgroundColor: 'rgba(144, 142, 148, 0.2)',
      borderColor: 'rgba(144, 142, 148, 1)',
      pointBackgroundColor: 'rgba(144, 142, 148, 1)',
      pointBorderColor: '#fff',
      data: expiredData.value
    }
  ],
  options: {
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return Number.isInteger(value) ? value : '';
          }
        }
      }
    }
  }
}));
</script>
