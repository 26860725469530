<template> 
  <div class="flex flex-row items-end gap-4 my-8 text-slate-500">
    <div class="flex flex-col gap-1">
      <label for="orderType" class="text-sm">Type</label>
      <select class="h-12 max-w-xs w-36 select select-bordered bg-slate-900" v-model="orderType" name="orderType">
        <option disabled selected value="">Type</option>
        <option :value="undefined">All</option>
        <option value="recibida">Received</option>
        <option value="emitida">Emitted</option>
      </select>
    </div>
    <div class="flex flex-col gap-1">
      <label for="status" class="text-sm">Status</label>
      <select class="h-12 max-w-xs w-36 select select-bordered bg-slate-900" v-model="status" name="status">
        <option disabled selected value="">Status</option>
        <option :value="undefined">All</option>
        <option value="aceptada">Accepted</option>
        <option value="rechazada">Rejected</option>
        <option value="cumplida">Completed</option>
        <option value="vencida">Expired</option>
      </select>
    </div>
    <div class="flex flex-col w-full gap-1">      
      <label for="date" class="text-sm">Creation Date</label>
      <VueTailwindDatepicker 
        name="date"
        v-model="date" 
        :formatter="formatter"
        input-classes="h-12 rounded-lg bg-slate-900"
        overlay
        as-single
        use-range
      />
    </div>
    <button
      class="btn btn-secondary"
      @click="filter"
      >Filtrar</button>
  </div>
</template>

<script setup>
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import { ref } from 'vue'

const date = ref([])
const formatter = ref({
  date: 'YYYY-MM-DD',
  month: 'MMM',
})
const status = ref("")
const orderType = ref("")

const emit = defineEmits(['filter'])

const filter = () => {
  emit('filter', date.value, orderType.value, status.value)
}
</script>