import { createApp } from 'vue'
import App from './App.vue'
import Navbar from './components/Navbar.vue';
import './index.css'
import router from './routes'
import { VueQueryPlugin } from '@tanstack/vue-query'


const components = {
  Navbar
};

const app = createApp(App);
Object.keys(components).forEach(key => {
  app.component(key, components[key]);
});

app.use(VueQueryPlugin);
app.use(router).mount('#app');