<template>
  <div v-if="orderItem" class="mt-8">
    <h2 class="text-xl font-bold text-center">Producto en la orden</h2>
    <div class="flex justify-between items-center py-2">
      <div>
        Producto: {{ orderItem.product }} - Cantidad: {{ orderItem.quantity }}
      </div>
      <div class="flex items-center">
        <label for="modify-quantity" class="mr-2">Modificar cantidad:</label>
        <input 
          id="modify-quantity" 
          type="number" 
          v-model="modifyQuantity" 
          min="1" 
          class="w-16 text-center border rounded-md" 
        />
      </div>
      <button @click="removeItem" class="text-red-500 ml-4">Eliminar producto</button>
    </div>
    <div class="text-center">
      <button 
        @click="submitOrder"
        class="mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded">
        Realizar Orden de Compra
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
const props = defineProps({
  orderItem: Object,
});

const emit = defineEmits(['remove-item', 'submit-order']);
const modifyQuantity = ref(props.orderItem.quantity);

const removeItem = () => {
  emit('remove-item');
}

const submitOrder = () => {
  emit('submit-order');
}

watch(modifyQuantity, (newQuantity) => {
  if (props.orderItem) {
    props.orderItem.quantity = newQuantity;
  }
});
</script>