<template>
  <div>
    <div
      :class="[
        'flex items-center gap-2 cursor-pointer hover:text-secondary',
        isExpanded ? 'text-primary' : 'text-muted-foreground'
      ]"
      @click="toggleExpand()"
    >
      {{ props.order.items.length }} SKUs
      <span class="w-4 h-4"></span>
    </div>
    <div v-if="isExpanded" class="mt-2 grid gap-1">
      <div
        v-for="(item, index) in props.order.items"
        :key="index"
        class="flex items-center justify-between"
      >
        <span>
          {{ item.sku }} x {{ item.quantity }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';

const isExpanded = ref(false);

const props = defineProps({
  order: {
    type: Object,
    required: true
  },
});

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

</script>
