<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
      <h2 class="text-xl font-semibold mb-4">{{ title }}</h2>
      <p class="mb-6">{{ message }}</p>
      <div class="text-right">
        <button @click="close" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  show: Boolean,
  title: String,
  message: String
});

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>