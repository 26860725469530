<template>
  <div class="w-full">
    <Filters v-if="props.enableFilters" @filter="fetchFilteredOrders"/>
    <table class="w-full border-collapse table-auto">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2 text-left text-black">Delivery Time</th>
          <th class="px-4 py-2 text-left text-black">Created At</th>
          <th class="px-4 py-2 text-left text-black">Order ID</th>
          <th class="px-4 py-2 text-left text-black">Items</th>
          <th class="px-4 py-2 text-left text-black">Status</th>
          <th class="px-4 py-2 text-right text-black">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="order in orders"
          :key="order.id"
          class="transition-colors border-b hover:bg-muted/50"
        >
          <td class="px-4 py-2">{{ order.deliveryDate }}</td>
          <td class="px-4 py-2">{{ order.createdAt }}</td>
          <td class="px-4 py-2">{{ order.orderId }}</td>
          <td class="px-4 py-2">
            <SKUData :order="order" />
          </td>
          <td class="px-4 py-2">
            <Badge :variant="order.status">
              {{ order.status.charAt(0).toUpperCase() + order.status.slice(1) }}
            </Badge>
          </td>
          <td class="px-4 py-2 text-right">
            <button class="btn btn-primary btn-sm" @click="props.handleViewMore(order)">View More</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex items-center justify-between mt-12">
      <button
        class="btn btn-primary"
        :disabled="currentPage === 1"
        @click="prevPage"
      >
        Previous
      </button>
      <span>Page {{ currentPage }}</span>
      <button
        class="btn btn-primary"
        :disabled="orders.length < pageSize"
        @click="nextPage"
      >
        Next
      </button>
    </div>
    <Chart :startDate="startDate" :endDate="endDate" :type="type" v-if="props.enableFilters" />
  </div>
  
</template>

<script setup>
  import { defineProps, onMounted, ref, watch } from 'vue';
  import Badge from './Badge.vue';
  import SKUData from './SKUData.vue';
  import Filters from './Filters.vue';
  import { getOrders } from '../api/order.js';
  import Chart from '../components/Chart.vue'

  const orders = ref([]);
  const currentPage = ref(1);
  const pageSize = 10;
  const startDate = ref(new Date().toISOString().split('T')[0]);
  const endDate = ref(new Date().toISOString().split('T')[0]);
  const type = ref('');
  const status = ref('');


  const fetchOrders = async () => {
    orders.value = await getOrders(
      currentPage.value,
    );
  };

  const fetchFilteredOrders = async (date=[], orderType='', orderStatus='') => {
    startDate.value = date[0] || new Date().toISOString().split('T')[0];
    endDate.value = date[1] || new Date().toISOString().split('T')[0];
    type.value = orderType;
    status.value = orderStatus;
    orders.value = await getOrders(
      currentPage.value,
      type.value,
      startDate.value,
      endDate.value,
      status.value
    );
  };

  onMounted(fetchOrders);

  const props = defineProps({
    handleViewMore: Function,
    enableFilters: Boolean,
  });

  watch(currentPage, (newVal) => {
    if (startDate.value || endDate.value || type.value || status.value) {
      fetchFilteredOrders(
        [startDate.value, endDate.value],
        type.value,
        status.value
      );
    } else {
      fetchOrders();
    }
  });

  const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  };

  const nextPage = () => {
    currentPage.value++;
  };
</script>
