<template>
  <div class="container w-full py-8 mx-auto justify-items-start">
    <h1 class="text-2xl font-bold">Statistics</h1>
    <OrderTable :enableFilters="true" :handleViewMore="handleViewMore" />
    <OpenModal v-if="selectedOrder" :selectedOrder="selectedOrder" :handleCloseDetails="handleCloseDetails" />
  </div>
</template>

<script setup>
  import OrderTable from '../components/OrderTable.vue'
  import OpenModal from '../components/OpenModal.vue'
  import { ref } from 'vue'

  const selectedOrder = ref(null)

  const handleViewMore = (order) => {
    selectedOrder.value = order
  }

  const handleCloseDetails = () => {
  selectedOrder.value = null
  }
</script>