<template>
  <div class="flex flex-col min-h-screen items-center">
    <navbar />
    <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
