<template>
    <div class="container py-8 mx-auto">
      <h1 class="mb-4 text-2xl font-bold">Orders</h1>
      <div class="overflow-x-auto">
        <OrderTable :handleViewMore="handleViewMore" :enableFilters="false" />
      </div>
      <OpenModal v-if="selectedOrder" :selectedOrder="selectedOrder" :handleCloseDetails="handleCloseDetails" />
    </div>
</template>
  
<script setup>
  import { ref } from 'vue'
  import OpenModal from '../components/OpenModal.vue'
  import OrderTable from '../components/OrderTable.vue'
  
  const selectedOrder = ref(null)
  const handleViewMore = (order) => {
    selectedOrder.value = order
  }
  const handleCloseDetails = () => {
  selectedOrder.value = null
  }
</script>