import axios from './setup';

export const createOrder = async (order) => {
  try {
    const response = await axios.post('/orders/emit', order);

    return {
      success: true,
      data: response.data
    };

  } catch (error) {
    if (error.response && error.response.data) {
      const errorResponse = error.response.data;

      if (errorResponse.detail) {
        return {
          error: true,
          message: errorResponse.detail
        };
      }

      if (errorResponse.errors) {
        return {
          error: true,
          message: errorResponse.errors.map(err => err.msg).join(', ')
        };
      }
    }
    return {
      error: true,
      message: 'Error inesperado. Inténtalo más tarde.'
    };
  }
};
