<template>
  <div class="mb-4">
    <label for="group" class="block mb-2 text-sm font-medium text-center text-gray-700">Seleccionar Grupo</label>
    <select v-model="selectedGroup" id="group" class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm"
      @change="onGroupSelected" :disabled="disabled">
      <option disabled value="">Select Group</option>
      <option v-for="(group, groupId) in groups" :key="groupId" :value="groupId">
        Group {{ groupId }}
      </option>
    </select>
    <p v-if="disabled" class="mt-2 text-sm text-center text-red-500">Para cambiar de grupo debes eliminar el producto actual.</p>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  groups: Object,
  disabled: Boolean,
})

const emit = defineEmits(['group-selected']);
const selectedGroup = ref(null);

const onGroupSelected = () => {
  emit('group-selected', selectedGroup.value);
}
</script>s