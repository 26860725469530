import axios from './setup';

export const getOrders = async (page, orderType, start_date, end_date, order_status) => {
    const orderTypeParam = orderType ? `&order_type=${orderType}` : '';
    const startDateParam = start_date ? `&start_date=${start_date}T00:00:00` : '';
    const endDateParam = end_date ? `&end_date=${end_date}T23:59:59` : '';
    const orderStatusParam = order_status ? `&order_status=${order_status}` : '';
    const response = await axios.get(`/orders?page=${page}${orderTypeParam}${startDateParam}${endDateParam}${orderStatusParam}`);
    return response.data;
}

export const getOrdersHistory = async (startDate, endDate, orderType) => {
    const orderTypeParam = orderType ? `&order_type=${orderType}` : '';
    const response = await axios.get(`/orders/history?start_date=${startDate}T00:00:00&end_date=${endDate}T23:59:59${orderTypeParam}`);
    return response.data;
}

export const getOrder = async (orderId) => {
    const response = await axios.get(`/orders/${orderId}`);
  return response.data;
}
