<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div class="w-full max-w-4xl p-6 bg-gray-100 rounded-lg shadow-lg">
        <div class="flex items-center justify-between mb-4">
          <h2 class="text-xl font-bold">Order Details</h2>
          <button class="btn btn-sm btn-circle btn-ghost right-2 top-2" @click="props.handleCloseDetails">✕</button>
        </div>
          <div>
            <div class="mb-2 font-semibold">Order Information</div>
            <div class="grid divide-y-2 grid-row-5">
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Date</span>
                <span class="my-2">{{ props.selectedOrder.createdAt }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Delivery Time</span>
                <span class="my-2">{{ props.selectedOrder.deliveryDate }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Order ID</span>
                <span class="my-2">{{ props.selectedOrder.orderId }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Provider</span>
                <span class="my-2">{{ order?.proveedor }}</span>
              </div>
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Client</span>
                <span class="my-2">{{ order?.cliente }}</span>
              </div>
              <div class="w-full py-2">
                <span class="text-muted-foreground">Progress</span>
                <span class="ml-2 font-bold">{{ order?.dispatched }} / {{ order?.quantity }}</span>
                <progress class="w-full progress progress-primary" :value="order?.dispatched || 0" :max="order?.quantity || 1"></progress>
              </div>
              <div class="flex items-center justify-between">
                <span class="my-2 text-muted-foreground">Status:</span>
                <span>
                  <Badge :variant="props.selectedOrder.status">
                    {{ props.selectedOrder.status.charAt(0).toUpperCase() + props.selectedOrder.status.slice(1) }}
                  </Badge>
                </span>
              </div>
              <div class="flex justify-between">
                <span class="my-2 text-muted-foreground">Items:</span>
                <div class="my-2 text-right">
                  <div v-for="item in props.selectedOrder.items" :key="item.sku">
                    {{ item.sku }} x {{ item.quantity }}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { defineProps, onMounted, watch, ref } from 'vue';
import Badge from './Badge.vue'
import { getOrder } from '../api/order'

const props = defineProps({
  selectedOrder: Object,
  handleCloseDetails: Function,
});

const order = ref(null)

const fetchOrderData = async () => {
  order.value = await getOrder(props.selectedOrder.orderId)
}

onMounted(() => {
  fetchOrderData()
})

watch(props, () => {
  fetchOrderData()
})

</script>