<template>
  <div class="container flex justify-center py-8 mx-auto">
    <div class="w-full max-w-lg p-6 bg-white border border-gray-300 rounded-lg shadow-lg">
      <LoadingSpinner v-if="loading" />
      <div v-else>
        <h1 class="mb-4 text-2xl font-bold text-center">Generate Order</h1>
        <GroupSelector :groups="groups" :disabled="orderItem !== null" @group-selected="handleGroupSelected" />
        <ProductQuantitySelector v-if="selectedGroup && !orderItem" :groups="groups" :selectedGroup="selectedGroup" @item-added="addItemToOrder" />
        <OrderSummary v-if="orderItem" :orderItem="orderItem" @remove-item="removeItem" @submit-order="submitOrder" />
        <Modal v-if="showModal" :show="showModal" :title="modalTitle" :message="modalMessage" @close="closeModal" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getGroupsSkus } from '../api/groupsSkus';
import { createOrder } from '../api/createOrder';
import GroupSelector from '../components/GroupSelector.vue';
import ProductQuantitySelector from '../components/ProductQuantitySelector.vue';
import OrderSummary from '../components/OrderSumary.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import Modal from '../components/AlertModal.vue';

const selectedGroup = ref(null);
const orderItem = ref(null);
const groups = ref({});
const loading = ref(false);

const showModal = ref(false);
const modalTitle = ref('');
const modalMessage = ref('');

const fetchGroupsAndSkus = async () => {
  try {
    const response = await getGroupsSkus();
    if (response['5']) {
      delete response['5'];
    }
    groups.value = response;
  } catch (error) {
    console.error('Error al obtener los grupos y SKUs:', error);
  }
};

const handleGroupSelected = (groupId) => {
  selectedGroup.value = groupId;
};

const addItemToOrder = (item) => {
  orderItem.value = item;
};

const removeItem = () => {
  orderItem.value = null;
  selectedGroup.value = null;
};

const closeModal = () => {
  showModal.value = false;
};

const showModalWithMessage = (title, message) => {
  modalTitle.value = title;
  modalMessage.value = message;
  showModal.value = true;
};

const validateOrder = () => {
  if (selectedGroup.value === '5') {
    showModalWithMessage('Error de Validación', 'No se puede seleccionar el proveedor 5.');
    return false;
  }

  if (orderItem.value.quantity > 5000) {
    showModalWithMessage('Error de Validación', 'La cantidad no puede exceder 5000 unidades.');
    return false;
  }

  return true;
};

const resetForm = () => {
  selectedGroup.value = null;
  orderItem.value = null;
};

const submitOrder = async () => {
  if (!validateOrder()) {
    return;
  }

  if (orderItem.value) {
    const vencimiento = new Date(Date.now() + 1000 * 60 * 60 * 3).toISOString();

    const order = {
      cliente: "5",
      proveedor: selectedGroup.value.toString(),
      sku: orderItem.value.product,
      cantidad: orderItem.value.quantity,
      vencimiento: vencimiento
    };

    loading.value = true;

    try {
      const response = await createOrder(order);

      if (response.error) {
        showModalWithMessage('Error al Crear la Orden', response.message);
        resetForm();
      } else {
        showModalWithMessage('Orden Creada', 'La orden ha sido creada exitosamente.');
        resetForm();
      }
    } catch (error) {
      console.error('Error inesperado:', error);
      showModalWithMessage('Error inesperado', 'Ocurrió un error inesperado. Por favor, inténtalo más tarde.');
    } finally {
      loading.value = false;
    }
  }
};

onMounted(() => {
  fetchGroupsAndSkus();
});
</script>