<template>
  <div v-if="selectedGroup" class="mb-4 flex space-x-4">
    <div class="w-1/2">
      <label for="product" class="block text-sm font-medium text-gray-700 mb-2">Producto</label>
      <select v-model="selectedProduct" id="product" class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm">
        <option disabled value="">Selecciona un producto</option>
        <option v-for="product in availableProducts" :key="product" :value="product">
          {{ product }}
        </option>
      </select>
    </div>

    <div class="w-1/2">
      <label for="quantity" class="block text-sm font-medium text-gray-700 mb-2">Cantidad</label>
      <input type="number" v-model="quantity" id="quantity" min="1" class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm" />
    </div>

    <div class="text-center">
      <button 
        @click="addItem"
        :disabled="!selectedProduct || !quantity"
        class="bg-blue-500 text-white font-bold py-2 px-4 rounded disabled:opacity-50">
        Agregar producto
      </button>
    </div>
  </div>
</template>
  
<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  groups: Object,
  selectedGroup: String
});

const selectedProduct = ref(null);
const quantity = ref(1);

const availableProducts = computed(() => {
  return props.selectedGroup ? props.groups[props.selectedGroup] : [];
});


const emit = defineEmits(['item-added']);

const addItem = () => {
  if (selectedProduct.value && quantity.value > 0) {
    emit('item-added', { product: selectedProduct.value, quantity: quantity.value });
    selectedProduct.value = null;
    quantity.value = 1;
  }
}
</script>