<template>
  <div :class="badgeClass">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'default',
  },
})

const badgeClass = computed(() => {
  return {
    "badge p-3 badge-outline": true,
    'badge-primary': props.variant === 'aceptada',
    'badge-secondary': props.variant === 'rechazada',
    'badge-neutral': props.variant === 'cumplida',
    'badge-ghost': props.variant === 'vencida',
  }
})
</script>

<script>
export default {
  name: 'CustomBadge',
}
</script>
