<template>
  <div class="mb-3 navbar bg-base-200">
    <div class="navbar-start">
      <div class="dropdown">
        <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h7" />
          </svg>
        </div>
        <ul
          tabindex="0"
          class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
          <li><a href="/">Metrics</a></li>
          <li><a href="/orders">Orders</a></li>
          <li><a href='/generateOrder'>Generate Order</a></li>
          <li><a href="/statistics">Statistics</a></li>
        </ul>
      </div>
    </div>
    <div class="navbar-center">
      <a class="text-xl btn btn-ghost" href="/">Granizo5 Coffee Shop</a>
    </div>
    <div class="navbar-end">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>